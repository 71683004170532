body {
  font-family: "Mitr";
}
code {
  font-family: "Mitr";
}

.dropdown-menu {
  min-width: 4rem;
}



.bs4-order-tracking {
  margin-bottom: 30px;
  overflow: hidden;
  color: #878788;
  padding-left: 0px;
  margin-top: 30px;
}
.bs4-order-tracking li {
  list-style-type: none;
  font-size: 13px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  color: #878788;
  text-align: center;
}
.bs4-order-tracking li:first-child:before {
  margin-left: 15px !important;
  padding-left: 11px !important;
  text-align: left !important;
}
.bs4-order-tracking li:last-child:before {
  margin-right: 5px !important;
  padding-right: 11px !important;
  text-align: right !important;
}
.bs4-order-tracking li > div {
  color: #fff;
  width: 29px;
  text-align: center;
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: #878788;
  border-radius: 50%;
  margin: auto;
}
.bs4-order-tracking li:after {
  content: "";
  width: 150%;
  height: 2px;
  background: #878788;
  position: absolute;
  left: 0%;
  right: 0%;
  top: 15px;
  z-index: -1;
}
.bs4-order-tracking li:first-child:after {
  left: 50%;
}
.bs4-order-tracking li:last-child:after {
  left: -50% !important;
  width: 100% !important;
}
.bs4-order-tracking li.active {
  font-weight: bold;
  color: #1e90ff;
}
.bs4-order-tracking li.active > div {
  background: #1e90ff;
}
.bs4-order-tracking li.active:after {
  background: #1e90ff;
}
.card-timeline {
  background-color: #fff;
  z-index: 0;
}
.bs4-order-tracking li.active1 {
  font-weight: bold;
  color: #DC143C;
}
.bs4-order-tracking li.active1 > div {
  background: #DC143C;
}
.bs4-order-tracking li.active1:after {
  background: #DC143C;
}
.bs4-order-tracking li.active2 > div {
  background: #878788;
}







